import { Box, Flex } from 'components/Box'
import { ChainWrapper } from 'components/WalletModal/styles'
import { Text } from 'components/Text'
import useTheme from 'hooks/useTheme'
import { setSelectedChainId } from 'state/wallets/actions'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'state'
import Image from 'components/Image'
import useViewport from 'hooks/useViewport'

const ChainBox = ({ selectedChain, setSelectedChain , chainListArray }) => {
  const { theme } = useTheme()
  const { width } = useViewport()
  const isMobile = width <= 990
  const dispatch = useDispatch<AppDispatch>()
  return (
    <Flex alignItems={'center'} width={'100%'} flexWrap={'wrap'}>
      {chainListArray?.map((item, index) => (
        <Flex width={isMobile ? '160px' : '180px'} key={index}>
        <ChainWrapper
          key={index}
          onClick={() => {
            setSelectedChain(item.chainId)
            dispatch(setSelectedChainId(item.chainId))
            
          }}
          className={selectedChain === item.chainId && 'checked'}
          // isSelected={selectedChain === item.chainId}
        >
          {/* {selectedChain === item.chainId && <TickIcon />} */}
          <Flex className="inner" flexDirection={'row'} alignItems={'center'}>
            <Box className="svg-icon"><Image width={'40px'} height={'40px'} src={item?.logoUri} /></Box>
            <Text
              fontFamily={theme.fonts.primary}
              fontSize={isMobile ? '14px' :'16px'}
              fontWeight={theme.fonts.medium}
              color={theme.colors.text}
              ml={'0.6rem'}
              lineHeight={"24px"}
            >
              {item.name}
            </Text>
          </Flex>
        </ChainWrapper>
        </Flex>
      ))}
    </Flex>
  )
}

export default ChainBox

import { Modal } from 'widgets/Modal'
import { FC, useState, useEffect } from 'react'
import { ContinueButton, LoaderBox, WalletWrapper } from './styles'
import ChainBox from './components/ChainBox'
import { SupportedChainId } from 'config/constants/chains'
import WalletBox from './components/WalletBox'
import { getChainID, getConnectedSolanaStatus, getWalletName, useWalletActionHandlers } from 'state/wallets/hooks'
import useAuth from 'hooks/useAuth'
import { WalletReadyState } from '@solana/wallet-adapter-base'
import { WalletStateEnum } from 'state/wallets/types'
import { useWallet } from '@solana/wallet-adapter-react'
import { Alert } from 'react-bootstrap'
import { Box, Flex } from 'components/Box'
import { solanaSupportiveChains } from 'views/Project/utils'
import { EVM_WALLETS } from 'config'
import copyToClipboard from 'utils/copyToClipboard'
import { CircleCheckedIcon, CopyIcon, DisconnectIcon, WalletIcon as WalletIconSVG } from 'components/Svg'
import { Text } from 'components/Text'
import useTheme from 'hooks/useTheme'
import Image from 'components/Image'
import { ContributionChainListResponse, WalletIconProps } from './type'
import { getContributionChainList } from 'state/user/queries/getContribution'
import { setSelectedChainId, setSelectedWalletName } from 'state/wallets/actions'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'state'
import useViewport from 'hooks/useViewport'
import { useUserManager } from 'state/user/hooks'
import WalletLoader from './components/WalletLoader'

const WalletModal = ({ handleDismiss }) => {
  const { width } = useViewport()
  const isMobile = width <= 990
  const [isLoading] = useState(false)
  const [user] = useUserManager()
  const [selectedChain, setSelectedChain] = useState<SupportedChainId>()
  const [chainListArray, setChainListArray] = useState<Array<ContributionChainListResponse>>()
  const [selectedWallet, setSelectedWallet] = useState(null)
  const [loading, setLoading] = useState(false)
  const { connectEVM, error, walletConnected } = useAuth()
  const { onWalletStateUpdate } = useWalletActionHandlers()
  const { select } = useWallet()
  const { getConnectedWalletAddress } = useWalletActionHandlers()
  const activeUserAddress = getConnectedWalletAddress()
  const { wallets: solanaWallets } = useWallet()
  const [copied, setCopied] = useState(false)
  const { disconnectEVM } = useAuth()
  const { theme } = useTheme()
  const walletName = getWalletName()
  const selectedChainID = getChainID()
  const dispatch = useDispatch<AppDispatch>()
  const solanaConnected = getConnectedSolanaStatus()
  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false)
      }, 1000)
    }
  }, [copied])

  //close the modal when user connected for the first time
  useEffect(() => {
    if (walletConnected || solanaConnected) {
      handleDismiss()
    }
  }, [walletConnected, solanaConnected])

  useEffect(() => {
    setLoading(true)
    getContributionChainList().then((chainList) => {
      setChainListArray(chainList)
      setSelectedChain(chainList[0].chainId)
      setLoading(false)
    })
  }, [])
  return (
    <Modal
      hideCloseButton={false}
      onDismiss={handleDismiss}
      title={loading ? '' : activeUserAddress && user?.jwt ? 'Wallet & Chain' : 'Select a chain & wallet'}
      minWidth={'100%'}
      minHeight={'100%'}
    >
      {loading ? (
          <WalletLoader/>
      ) : (
        <>
          {activeUserAddress && user?.jwt ? (
            <WalletWrapper className={'wallet-wrapper'}>
              <Flex className="border-none">
                {solanaSupportiveChains.has(selectedChainID)
                  ? solanaWallets.map(
                      (item, index) =>
                        walletName === item.adapter.name && (
                          <Flex
                            key={index}
                            className="inner inner-wallet"
                            minHeight={'0'}
                            flexDirection={'row'}
                            alignItems={'center'}
                          >
                            <Box className="svg-icon">
                              <WalletIcon wallet={item} />
                            </Box>
                            <Text
                              fontFamily={theme.fonts.primary}
                              fontSize={'16px'}
                              fontWeight={theme.fonts.medium}
                              color={theme.colors.text}
                              ml={'0.6rem'}
                            >
                              {item.adapter.name}
                            </Text>
                          </Flex>
                        ),
                    )
                  : EVM_WALLETS.map(
                      (item, index) =>
                        walletName === item.adapter.name && (
                          <Flex
                            key={index}
                            className="inner inner-wallet"
                            minHeight={'0'}
                            flexDirection={'row'}
                            alignItems={'center'}
                          >
                            <Box className="svg-icon">
                              <img src={item.adapter.icon} width={'40px'} height={'40px'} alt="" />
                            </Box>
                            <Text
                              fontFamily={theme.fonts.primary}
                              fontSize={'16px'}
                              fontWeight={theme.fonts.medium}
                              color={theme.colors.text}
                              ml={'0.6rem'}
                            >
                              {item.adapter.name}
                            </Text>
                          </Flex>
                        ),
                    )}
                {chainListArray?.map(
                  (item, index) =>
                    selectedChainID === item.chainId && (
                      <Flex
                        key={index}
                        className="inner inner-wallet border-none"
                        minHeight={'0'}
                        flexDirection={'row'}
                        alignItems={'center'}
                      >
                        <Box className="svg-icon">
                          <Image width={'40px'} height={'40px'} src={item?.logoUri} />
                        </Box>
                        <Text
                          fontFamily={theme.fonts.primary}
                          fontSize={'14px'}
                          fontWeight={theme.fonts.light}
                          color={theme.colors.text}
                          ml={'0.6rem'}
                        >
                          {item.name}
                        </Text>
                      </Flex>
                    ),
                )}
              </Flex>
              <Flex
                className="inner inner-wallet border-none wallet-icon"
                borderRadius={'8px'}
                background={`${theme.colors.backgroundAlt}80`}
                p={'0.6rem'}
              >
                <WalletIconSVG />{' '}
                <Text
                  color={theme.colors.textDisabled}
                  fontSize={'14px'}
                  fontWeight={'300'}
                  lineHeight={'1.3'}
                  ml={'0.4rem'}
                  className="address-styles"
                >
                  {activeUserAddress}
                </Text>
              </Flex>
              <Flex className="inner inner-wallet border-none" flexDirection={'column'} py={'1px'}>
                <Flex width={'100%'} alignItems={'center'} justifyContent={isMobile ? 'space-between' : 'center'}>
                  <Flex
                    flexDirection={'row'}
                    alignItems={'center'}
                    role={'button'}
                    height={'100%'}
                    minWidth={'100px'}
                    onClick={() => {
                      copyToClipboard(activeUserAddress)
                      setCopied(true)
                    }}
                  >
                    {copied ? (
                      <CircleCheckedIcon fill={'none'} mr={'5px'} height={'20'} />
                    ) : (
                      <CopyIcon stroke={'#4276FF'} mr={'5px'} width={'20'} height={'20'} />
                    )}
                    <Text fontSize={'x-small'} className={copied ? 'copied-color' : 'text-color'}>
                      {copied ? 'Copied' : 'Copy'}
                    </Text>
                  </Flex>

                  <Flex
                    flexDirection={'row'}
                    alignItems={'center'}
                    mx={isMobile ? '0' : '0.4rem'}
                    role={'button'}
                    className="disconnet-btn"
                    onClick={() => {
                      disconnectEVM()
                      dispatch(setSelectedWalletName(EVM_WALLETS[0].adapter.name))
                    }}
                  >
                    <DisconnectIcon width={'30'} height={'30'} />
                    <Text>Disconnect</Text>
                  </Flex>
                </Flex>
              </Flex>
            </WalletWrapper>
          ) : (
            <WalletWrapper>
              <Flex flexDirection={'column'} flex={1}>
                <ChainBox
                  selectedChain={selectedChain}
                  setSelectedChain={setSelectedChain}
                  chainListArray={chainListArray}
                />
              </Flex>
              <Flex flexDirection={'column'} justifyContent={isMobile && 'space-between'} flex={1}>
                <WalletBox selectedChain={selectedChain} setSelectedWallet={setSelectedWallet} />
                <ContinueButton
                  isLoading={isLoading}
                  disabled={selectedChain === null}
                  onClick={async () => {
                    // if wallet is already connected, dismiss modal on continue

                    // handleDismiss()
                    // return
                    dispatch(setSelectedChainId(selectedChain))
                    if (solanaSupportiveChains.has(selectedChain)) {
                      // if Solana is selected
                      if (
                        selectedWallet.readyState === WalletReadyState.Installed ||
                        selectedWallet.readyState === WalletReadyState.Loadable
                      ) {
                        await select(selectedWallet.adapter.name)
                        onWalletStateUpdate(WalletStateEnum.READY)
                      } else {
                        console.error('Error', 'Please Install Wallet Extension')
                      }
                      return
                    }

                    // if EVM is selected
                    await connectEVM(selectedWallet.connector, selectedChain)
                  }}
                >
                  {'Connect'}
                </ContinueButton>
              </Flex>
            </WalletWrapper>
          )}
        </>
      )}

      {/*{error && <Text mt='1.4rem' fontFamily={theme.fonts.primary} color={theme.colors.failure}*/}
      {/*       fontWeight={theme.fonts.regular}>{error}</Text>}*/}
      {error && <Alert variant={'danger'}>{error}</Alert>}
    </Modal>
  )
}

const WalletIcon: FC<WalletIconProps> = ({ wallet }) => {
  return <Image src={wallet.adapter.icon} width={'40px'} height={'40px'} />
}

export default WalletModal

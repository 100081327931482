import { FC, useEffect, useState } from 'react'
import { Box, Flex } from 'components/Box'
import { ChainWrapper } from 'components/WalletModal/styles'
import { Text } from 'components/Text'
import useTheme from 'hooks/useTheme'
import { EVM_WALLETS } from 'config'
import { useWalletState } from 'state/wallets/hooks'
import { useWallet } from '@solana/wallet-adapter-react'
import { WalletIconProps } from 'components/WalletModal/type'
import Image from 'components/Image'
import { SupportedChainId } from 'config/constants/chains'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'state'
import { setSelectedWalletName } from 'state/wallets/actions'

const WalletBox = ({ selectedChain, setSelectedWallet }) => {
  const { theme } = useTheme()
  const dispatch = useDispatch<AppDispatch>()
  const [selectedWallet, setSelected] = useState(EVM_WALLETS[0])
  const walletInfo = useWalletState()
  const { wallets: solanaWallets } = useWallet()

  useEffect(() => {
    setSelectedWallet(selectedWallet)
  }, [selectedWallet])

  //List of wallets For Solana
  const SolanaWalletRow = () => {
    return (
      <Flex mb={'30px'}>
        {solanaWallets.map((item, index) => (
          <ChainWrapper
            key={index}
            flexDirection={'column'}
            alignItems={'center'}
            className={selectedWallet.adapter.name === item.adapter.name ? 'selected pointer' : 'pointer'}
            mt="12px"
            onClick={async () => {
              setSelected(item as any)
              dispatch(setSelectedWalletName(item.adapter.name))
            }}
          >
            {selectedWallet.adapter.name === item.adapter.name  ? 
            <Flex className="inner" flexDirection={'column'} alignItems={'center'}>
            <Box className="svg-icon">
              <WalletIcon wallet={item} />
            </Box>
            <Text
              fontFamily={theme.fonts.primary}
              fontSize={'11px'}
              fontWeight={theme.fonts.regular}
              color={theme.colors.textSubtle}
              mt={'0.6rem'}
            >
              {item.adapter.name}
            </Text>
          </Flex>
            : 
            <Flex className="inner" flexDirection={'column'} alignItems={'center'}>
              <Box className="svg-icon">
                <WalletIcon wallet={item} />
              </Box>
              <Text
                fontFamily={theme.fonts.primary}
                fontSize={'11px'}
                fontWeight={theme.fonts.regular}
                color={theme.colors.textSubtle}
                mt={'0.6rem'}
              >
                {item.adapter.name}
              </Text>
            </Flex>
             }
          </ChainWrapper>
        ))}
      </Flex>
    )
  }
  //List of wallets for EVM
  const EvmWalletRow = () => {
    return (
      <Flex mb={'30px'}>
        {EVM_WALLETS.map((item, index) => (
          <ChainWrapper
            key={index}
            flexDirection={'column'}
            alignItems={'center'}
            className={selectedWallet.adapter.name === item.adapter.name ? 'selected pointer' : 'pointer'}
            mt="12px"
            onClick={async () => {
              setSelected(item)
              dispatch(setSelectedWalletName(item.adapter.name))
            }}
          >
            {walletInfo && selectedWallet.adapter.name === item.adapter.name  ? 
            <Flex className="inner" flexDirection={'column'} alignItems={'center'}>
            <Box className="svg-icon">
              <img src={item.adapter.icon} width={'40px'} height={'40px'} alt="" />
            </Box>
            <Text
              fontFamily={theme.fonts.primary}
              fontSize={'11px'}
              fontWeight={theme.fonts.regular}
              color={theme.colors.textSubtle}
              mt={'0.6rem'}
            >
              {item.adapter.name}
            </Text>
          </Flex>
            : 
            <Flex className="inner" flexDirection={'column'} alignItems={'center'}>
              <Box className="svg-icon">
                <img src={item.adapter.icon} width={'40px'} height={'40px'} alt="" />
              </Box>
              <Text
                fontFamily={theme.fonts.primary}
                fontSize={'11px'}
                fontWeight={theme.fonts.regular}
                color={theme.colors.textSubtle}
                mt={'0.6rem'}
              >
                {item.adapter.name}
              </Text>
            </Flex>
             }
          </ChainWrapper>
        ))}
      </Flex>
    )
  }

  return (
    <Flex mb={'30px'}>
      {selectedChain === SupportedChainId.SOLANA ||  selectedChain === SupportedChainId.SOLANA_DEV_NET ? <SolanaWalletRow /> : <EvmWalletRow />}
    </Flex>
  )
}

const WalletIcon: FC<WalletIconProps> = ({ wallet }) => {
  return <Image src={wallet.adapter.icon} width={'40px'} height={'40px'} />
}

export default WalletBox

import { FC } from 'react'
import { SvgProps } from 'components/Svg/types'

export interface Address {
  97?: string
  56?: string
  1?: string
  4?: string
}

export type Images = {
  lg: string
  md: string
  sm: string
  ipfs?: string
}

export type PageMeta = {
  title: string
  description?: string
  image?: string
}

export enum ConnectorNames {
  Injected = 'injected',
}
export const connectorLocalStorageKey = 'connectorIdv2'

export type Login = (connectorId: ConnectorNames) => void

export interface Config {
  title: string
  icon: FC<SvgProps>
  connectorId: ConnectorNames
}

export interface Language {
  code: string
  language: string
  locale: string
}

export enum FetchStatus {
  Idle = 'IDLE',
  Fetching = 'FETCHING',
  Fetched = 'FETCHED',
  Failed = 'FAILED',
}

export enum STATUS_STATE {
  ACTIVE = 'ACTIVE',
  SCHEDULED = 'SCHEDULED',
  SETTLED = 'SETTLED',
  COMPLETE = 'COMPLETE',
  ABORTED = 'ABORTED',
  NO_STATUS = '',
  CANCELED = 'CANCELED',
  VESTING = 'VESTING',
  SOLDOUT = 'SOLD OUT',
}
export enum PROJECT_SALE_STATUS {
  CREATED = "CREATED",
  INITIATED = "INITIATED",
  ATTESTED = "ATTESTED",
  SEALED = "SEALED",
  ABORTED = "ABORTED",
  SEAL_ERROR = "SEAL_ERROR",
  ATTEST_ERROR = "ATTEST_ERROR",
  ABORT_ERROR = "ABORT_ERROR",
  SETTLED = "SETTLED"
}

export enum ROUTES {
  HOME = '/',
  APPLY = '/apply',
}

export const SALE_ENDED_SET = new Set([
  STATUS_STATE.CANCELED,
  STATUS_STATE.COMPLETE,
  STATUS_STATE.SOLDOUT,
  STATUS_STATE.VESTING,
])

export enum ENVIRONMENTS {
  PRODUCTION = 'production',
  STAGING = 'staging',
  DEVELOPMENT = 'development',
}

export const PROJECT_SALE_COMPLETED = new Set([
  PROJECT_SALE_STATUS.ABORTED,
  PROJECT_SALE_STATUS.SEALED,
  PROJECT_SALE_STATUS.SETTLED,
])

export const SEALED_SETTLED = new Set([
  PROJECT_SALE_STATUS.SEALED,
  PROJECT_SALE_STATUS.SETTLED,
])
/**
 * List of all the networks supported by the Uniswap Interface
 */
export enum SupportedChainId {
  SOLANA_DEV_NET = 102, // for Non EVM we are putting these Chain IDs to be in negative.
  SOLANA = 101, // for Non EVM we are putting these Chain IDs to be in negative.
  TERRA = 202, // for Non EVM we are putting these Chain IDs to be in negative.
  ETHEREUM = 1,
  POLYGON = 137,
  BINANCE = 56,
  AVAX = 43114,
  FANTOM = 250,
  FUJI = 43113,
  GOERLI = 5,
  BINANCE_TESTNET = 97,
  FANTOM_TESTNET = 4002,
  MUMBAI = 80001,
  ARBITRUM = 42161,
  OPTIMISM = 10,
  ARBITRUM_TESTNET = 421613,
}

export const CHAIN_IDS_TO_NAMES = {
  [SupportedChainId.ETHEREUM]: 'ethereum',
  [SupportedChainId.SOLANA]: 'solana',
  [SupportedChainId.SOLANA_DEV_NET]: 'solana devnet',
  [SupportedChainId.TERRA]: 'terra',
  [SupportedChainId.POLYGON]: 'polygon',
  [SupportedChainId.BINANCE]: 'binance',
  [SupportedChainId.FANTOM]: 'fantom',
  [SupportedChainId.AVAX]: 'avax',
  [SupportedChainId.FUJI]: 'Fuji',
  [SupportedChainId.GOERLI]: 'Goerli',
  [SupportedChainId.BINANCE_TESTNET]: 'Binance testnet',
  [SupportedChainId.FANTOM_TESTNET]: 'Fantom testnet',
  [SupportedChainId.MUMBAI]: 'Mumbai',
  [SupportedChainId.ARBITRUM]: 'Arbitrum',
  [SupportedChainId.OPTIMISM]: 'Optimism',
  [SupportedChainId.ARBITRUM_TESTNET]: 'Arbitrum testnet',
  4: 'rinkeby',
}

/**
 * Array of all the supported chain IDs
 */
export const ALL_SUPPORTED_CHAIN_IDS: SupportedChainId[] = Object.values(SupportedChainId).filter(
  (id) => typeof id === 'number',
) as SupportedChainId[]

/**
 * All the chain IDs that are running the EVM protocol.
 */
export const EVM_CHAIN_IDS = [
  SupportedChainId.ETHEREUM,
  SupportedChainId.POLYGON,
  SupportedChainId.BINANCE,
  SupportedChainId.AVAX,
  SupportedChainId.FANTOM,
  SupportedChainId.FUJI,
  SupportedChainId.GOERLI,
  SupportedChainId.BINANCE_TESTNET,
  SupportedChainId.FANTOM_TESTNET,
  SupportedChainId.MUMBAI,
  SupportedChainId.ARBITRUM,
  SupportedChainId.OPTIMISM,
  SupportedChainId.ARBITRUM_TESTNET
] as const

export const SupportedEVMChainId = (chainId) => {
  return EVM_CHAIN_IDS.findIndex((evmChain) => evmChain === chainId) > -1
}

export const ENVIRONMENT = process.env.REACT_APP_ENV || 'development'
export const NEXA_API_BASE_URL = process.env.REACT_APP_NEXA_API_BASE_URL || ''
export const NEXA_API_BASE_URL_SUBSCRIPTION = process.env.REACT_APP_NEXA_API_BASE_URL_SUBSCRIPTION || ''
export const PUSHER_ID = process.env.REACT_APP_PUSHER_ID || ''
export const PUSHER_KEY = process.env.REACT_APP_PUSHER_KEY || ''
export const PUSHER_SECRET = process.env.REACT_APP_PUSHER_SECRET || ''
export const PUSHER_CLUSTER = process.env.REACT_APP_PUSHER_CLUSTER || ''
export const SOLANA_WALLET_INITIALIZER_KEY = process.env.REACT_APP_SOLANA_WALLET_INITIALIZER_KEY || ''
export const WORMHOLE_RPC_HOSTS_URL = process.env.WORMHOLE_RPC_HOSTS_URL || ''
export const S3_BUCKET = process.env.REACT_APP_S3_BUCKET || ''
export const S3_REGION = process.env.REACT_APP_S3_REGION || ''
export const S3_ACCESS_KEY_ID = process.env.REACT_APP_S3_ACCESS_KEY_ID || ''
export const S3_SECRET_ACCESS_KEY = process.env.REACT_APP_S3_SECRET_ACCESS_KEY || ''
export const SOL_ALT_RPC_URL = process.env.REACT_APP_SOL_ALT_RPC_URL || ''

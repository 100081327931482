import {
  EthIconWhite,
  SolanaWhite,
  AvalancheIconWhite,
  SolanaChainIcon,
  AvaxChainIcon,
  EthChainIcon,
  FantomChainIcon,
  PolygonChainIcon,
  BinanceBadgeIcon,
  ArbitrumChainIcon,
  OptimismChainIcon,
} from 'components/Svg'
import Metamask from 'components/Svg/Icons/Metamask'
import { SupportedChainId } from './chains'
import { Config, ConnectorNames, STATUS_STATE } from './types'
import { AbortedIcon, CheckedIcon, ScheduleIcon, SettledIcon } from 'components/Svg'

export const PAST_SECOND_INTERVAL = 1000

export const FAST_INTERVAL = 10000
export const SLOW_INTERVAL = 60000

export const ETHER = '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee'
export const authMessage = `Nexa Network uses this cryptographic signature in place of a password, verifying that you are the owner of this address.`

const connectors: Config[] = [
  {
    title: 'MetaMask',
    icon: Metamask,
    connectorId: ConnectorNames.Injected,
  },
]

export default connectors

export const NetworkContextName = 'NETWORK'

export const ChainArray = [
  {
    icon: EthIconWhite,
    name: 'Goerli Testnet',
    chainId: SupportedChainId.GOERLI,
  },
  {
    icon: AvalancheIconWhite,
    name: 'Fuji Testnet',
    chainId: SupportedChainId.FUJI,
  },
  {
    icon: SolanaWhite,
    name: 'Solana testnet',
    chainId: SupportedChainId.SOLANA_DEV_NET,
  },
  {
    icon: EthIconWhite,
    name: 'Ethereum',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    icon: AvalancheIconWhite,
    name: 'Avalanche',
    chainId: SupportedChainId.AVAX,
  },
  {
    icon: SolanaWhite,
    name: 'Solana',
    chainId: SupportedChainId.SOLANA,
  },
  {
    icon: FantomChainIcon,
    name: 'Fantom',
    chainId: SupportedChainId.FANTOM,
  },
  {
    icon: PolygonChainIcon,
    name: 'Binance',
    chainId: SupportedChainId.BINANCE,
  },
  {
    icon: PolygonChainIcon,
    name: 'Polygon',
    chainId: SupportedChainId.POLYGON,
  },
]

export const ProjectStatuIcons = {
  [STATUS_STATE.ACTIVE]: {
    icon: <CheckedIcon />,
    color: 'success',
  },
  [STATUS_STATE.SETTLED]: {
    icon: <SettledIcon />,
    color: 'warning',
  },
  [STATUS_STATE.ABORTED]: {
    icon: <AbortedIcon />,
    color: 'failure',
  },
  [STATUS_STATE.COMPLETE]: {
    icon: <CheckedIcon />,
    color: 'success',
  },
  [STATUS_STATE.SCHEDULED]: {
    icon: <ScheduleIcon />,
    color: 'primary',
  },
  [STATUS_STATE.NO_STATUS]: {
    icon: <ScheduleIcon />,
    color: 'textTertiary',
  },
}

export const BlockchainIcons = {
  [SupportedChainId.SOLANA]: <SolanaChainIcon width={'34px'} height={'34px'} />,
  [SupportedChainId.SOLANA_DEV_NET]: <SolanaChainIcon width={'34px'} height={'34px'} />,
  [SupportedChainId.AVAX]: <AvaxChainIcon width={'34px'} height={'34px'} />,
  [SupportedChainId.FUJI]: <AvaxChainIcon width={'34px'} height={'34px'} />,
  [SupportedChainId.ETHEREUM]: <EthChainIcon width={'34px'} height={'34px'} />,
  [SupportedChainId.GOERLI]: <EthChainIcon width={'34px'} height={'34px'} />,
  [SupportedChainId.FANTOM]: <FantomChainIcon width={'34px'} height={'34px'} />,
  [SupportedChainId.FANTOM_TESTNET]: <FantomChainIcon width={'34px'} height={'34px'} />,
  [SupportedChainId.POLYGON]: <PolygonChainIcon width={'34px'} height={'34px'} />,
  [SupportedChainId.MUMBAI]: <PolygonChainIcon width={'34px'} height={'34px'} />,
  [SupportedChainId.BINANCE]: <BinanceBadgeIcon width={'34px'} height={'34px'} />,
  [SupportedChainId.BINANCE_TESTNET]: <BinanceBadgeIcon width={'34px'} height={'34px'} />,
  [SupportedChainId.ARBITRUM]: <ArbitrumChainIcon width={'34px'} height={'34px'} />,
  [SupportedChainId.OPTIMISM]: <OptimismChainIcon width={'34px'} height={'34px'} />,
  [SupportedChainId.ARBITRUM_TESTNET]: <ArbitrumChainIcon width={'34px'} height={'34px'} />,
}

export const dateFormat = 'MMM DD, YYYY hh:mm a'
export const date = 'MMM DD, YYYY'
export const time = 'hh:mm a'
export const dateFormatWithSpace = 'MMM DD, YYYY\xa0\xa0\xa0\xa0hh:mm a'

export const FIXED = 'Fixed'
export const NONE = 'None'

export const landingPageSet = new Set([
  '/','/terms', '/privacy'
])

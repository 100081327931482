import React from 'react'
import { LoaderBox } from '../../styles'
import SkeletonLoader from 'components/SkeletonLoader'
import { NexaLoaderWrapper } from 'components/NexaLoader'
import { Box } from 'components/Box'

const WalletLoader = () => {
  return (
    <NexaLoaderWrapper>
      <Box ml={'1%'} width={'100%'} height={'30px'} mb={'35px'} mt={'150px'}>
        <SkeletonLoader width={'15%'} height={'100%'} borderRadius={'12px'} />
      </Box>
      <LoaderBox>
        <LoaderBox>
          <SkeletonLoader width={'100%'} height={'100%'} borderRadius={'12px'} />
        </LoaderBox>
        <LoaderBox>
          <SkeletonLoader width={'100%'} height={'100%'} borderRadius={'12px'} />
        </LoaderBox>
      </LoaderBox>
    </NexaLoaderWrapper>
  )
}

export default WalletLoader
